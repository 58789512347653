.main-div-verify {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  text-align: center;
}

.custom-container {
  min-height: 100vh;
}

.block-element {
  display: block;
  color: #ed1c24;
}

.auth-container {
  padding: 2rem;

  align-items: center;
  border-radius: 15px;

  background: linear-gradient(118deg, #151515 0%, #050505 8.21%, #202020 100%);
  gap: 6rem;
  z-index: 10;
  color: rgb(34, 34, 34);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 0.5px 0.5px 0.5px;
  color: #6e6eff;
}

.auth-container h2 {
  padding-bottom: 1rem;
  border-bottom: solid rgb(199, 197, 197);
  padding-top: 1rem;
  border-top: solid rgb(199, 197, 197);
}

.connect-btn {
  color: white;
  padding: 1rem;
  width: 70%;
  font-family: sans-serif;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 78%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 10%;
}

.connected-tg-header {
  color: #6e6eff;
}

.result-message {
  z-index: 10;
  text-align: center;
}

.shadow-border {
  font-family: sans-serif;
}

.telegram-link-button-verify {
  margin-top: 3rem;
  display: flex;

  flex-direction:column;
  gap:1rem;
  align-items:center;
}

.telegram-widget-login-btn{
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:1.5rem;
}
body {
  font-family: 'Questrial', sans-serif;

  color: #fff;
  background-color: $bg;

  overflow: hidden;
}

footer {
  overflow: hidden;
}

section {
  height: 100vh;
  position: relative;

  &.animated {
    #hero-light img {
      opacity: 1 !important;
    }
  }

  &.animated {
    .reveal {
      transform: translate(0) !important;
      translate: 0 !important;
      rotate: 0deg !important;
      scale: 1 !important;
      opacity: 1 !important;
    }

    .reveal-width {
      width: 100% !important;
    }
  }
}

div {
  &.animated {
    .reveal {
      transform: translate(0) !important;
      translate: 0 !important;
      rotate: 0deg !important;
      scale: 1 !important;
      opacity: 1 !important;
    }

    .reveal-width {
      width: 100% !important;
    }
  }
}

.wire {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;

  &.anim {
    animation-name: test;
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    animation-duration: 1.5s;
    // animation-iteration-count: fo
    animation-direction: reverse;
  }
}

header {
  &.animated {
    .reveal {
      transform: translate(0) !important;
      translate: 0 !important;
      rotate: 0deg !important;
      scale: 1 !important;
      opacity: 1 !important;
    }

    .reveal-width {
      width: 100% !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 97.5%;
  font-weight: 400;
}

h1 {
  font-size: 76px;

  @media (max-width: $laptop_big) {
    font-size: 54px;
  }

  @media (max-width: $laptop_small) {
    font-size: 48px;
  }

  @media (max-width: $tablet) {
    font-size: 43px;
  }
}

h2 {
  font-size: 64px;

  @media (max-width: $laptop_big) {
    font-size: 58px;
  }

  @media (max-width: $laptop) {
    font-size: 50px;
  }

  @media (max-width: $laptop_small) {
    font-size: 44px;
  }

  @media (max-width: $tablet) {
    font-size: 43px;
  }
}

h3 {
  font-size: 56px;

  @media (max-width: $laptop_big) {
    font-size: 50px;
  }

  @media (max-width: $laptop) {
    font-size: 46px;
  }

  @media (max-width: $laptop_small) {
    font-size: 43px;
  }
}

h4 {
  font-size: 40px;

  @media (max-width: $laptop_big) {
    font-size: 38px;
  }

  @media (max-width: $laptop_small) {
    font-size: 36px;
  }
}

h5 {
  font-size: 28px;
}

section {
  overflow: hidden !important;

  .container {
    padding-block: 75px;

    @media (min-height: 1000px) {
      padding-block: 140px;
    }
  }
}

span {
  &.white {
    color: #fff;
  }

  &.section-span {
    font-size: 14px;
    color: $gray;
  }
}

.button-group {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 5px;

  background-color: $black;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

#roadmap {
  .container {
    @media (min-height: 1080px) and (min-width: 769px) {
      margin: auto 0;
      height: 100% !important;
    }
  }

  .fp-overflow {
    @media (min-height: 1080px) and (min-width: 769px) {
      margin: auto 0;
    }
  }
}

.container {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;

  padding-inline: 15px !important;
}

.fp-overflow {
  height: 100% !important;
  width: 100% !important;

  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.hidden-letter {
  opacity: 0;
  pointer-events: none;
  display: inline-block;
}

.reveal {
  transition-duration: 0.3s;
  transition-property: opacity, scale, translate, rotate, transform;

  &.reveal-width {
    transition-property: opacity, scale, translate, rotate, transform, width;
  }
}

.fp-watermark {
  display: none;
}

@keyframes test {
  25% {
    opacity: 1;
    stroke-dasharray: 750;
  }

  30% {
    stroke-dasharray: 725;
  }

  35% {
    stroke-dasharray: 700;
  }

  40% {
    stroke-dasharray: 675;
  }

  45% {
    stroke-dasharray: 650;
  }

  50% {
    opacity: 1;
    stroke-dasharray: 625;
  }

  55% {
    opacity: 1;
    stroke-dasharray: 600;
  }

  60% {
    opacity: 1;
    stroke-dasharray: 575;
  }

  65% {
    opacity: 1;
    stroke-dasharray: 550;
  }

  70% {
    opacity: 1;
    stroke-dasharray: 525;
  }

  75% {
    opacity: 1;
    stroke-dasharray: 500;
  }

  80% {
    opacity: 1;
    stroke-dasharray: 475;
  }

  85% {
    opacity: 1;
    stroke-dasharray: 450;
  }

  90% {
    opacity: 1;
    stroke-dasharray: 425;
  }

  95% {
    opacity: 1;
    stroke-dasharray: 400;
  }

  100% {
    opacity: 0;
    stroke-dasharray: 350;
  }
}

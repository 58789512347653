@import "/src/app/styles/variables";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 88;

  transition: background-color 0.4s ease !important;

  &.filled {
    background-color: $bg;
  }

  .menuBtn {
    display: none;

    width: 32px;
    height: 32px;

    position: relative;

    opacity: 0;
    translate: -100% 0;

    transition-duration: 1s !important;
    transition-delay: 0.5s !important;

    &.active {
      svg {
        &:nth-child(1) {
          opacity: 0 !important;
        }

        &:nth-child(2) {
          opacity: 1 !important;
        }
      }
    }

    svg {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      opacity: 0;
      transition: opacity 0.4s ease;

      pointer-events: none;

      &:nth-child(1) {
        opacity: 1;
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }

    @media (max-width: $tablet) {
      display: block;
    }
  }

  .menu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    translate: 0 100%;

    height: 100dvh;
    width: 100%;

    padding: 80px 16px 110px;

    z-index: 2;

    background-image: url('/images/Menu/bg.png');
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    opacity: 0;

    transition: opacity 0.5s ease;

    pointer-events: none;

    &.active {
      pointer-events: all;
      opacity: 1 !important;
    }

    .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 45px;

      div[class='button-group'] {
        button {
          z-index: 1;
          min-height: 52px !important;
          font-size: 14px !important;
          white-space: nowrap;
        }
      }

      .links {
        display: flex;

        max-width: 60%;
        width: 100%;

        align-items: center;
        justify-content: space-between;

        a {
          font-size: 20px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 36px;

      li {
        font-size: 32px;
        text-transform: capitalize;
      }
    }
  }

  .logo {
    width: 102px;
    height: 40px;

    opacity: 0;
    translate: 100% 0;

    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    transition-delay: 0.5s;
  }

  div[class='container'] {
    padding-block: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    @media (max-width: 1760px) and (min-height: 950px) {
      padding: 20px !important;
    }

    @media (min-width: 2000px) and (min-height: 1080px) {
      padding: 50px !important;
    }

    .logo > * {
      @media (max-width: 1760px) and (min-height: 950px) {
        scale: 1.25 !important;
        transform-origin: left top;
      }

      @media (min-width: 2000px) and (min-height: 1080px) {
        scale: 2 !important;
        transform-origin: left top;
      }
    }

    > * {
      ul {
        @media (max-width: 1760px) and (min-height: 950px) {
          scale: 1.25 !important;
          transform-origin: right top;
        }

        @media (min-width: 2000px) and (min-height: 1080px) {
          scale: 2 !important;
          transform-origin: right top;
        }
      }
    }

    @media (max-width: $tablet) {
      padding: 35px 15px 0;
    }

    > div:nth-child(3) {
      position: absolute;
      top: 50%;
      left: 50%;

      translate: -50% -50%;

      @media (max-width: 1760px) and (min-height: 950px) {
        scale: 1.25 !important;
        transform-origin: center;
      }

      @media (min-width: 2000px) and (min-height: 1080px) {
        scale: 2 !important;
        transform-origin: center;
      }

      @media (max-width: $laptop_big) {
        scale: 0.8;
      }

      @media (max-width: $tablet) {
        display: none;
      }

      > div {
        opacity: 0;
        scale: 0.7;

        transition-duration: 1s;
        transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        transition-delay: 0.5s;
      }
    }
  }

  nav {
    opacity: 0;
    translate: -25% 0;

    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    transition-delay: 0.5s;

    @media (max-width: $tablet) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 30px;

      font-size: 14px;

      li {
        &.shortLink {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

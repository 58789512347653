@import "/src/app/styles/variables";

.features {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(5, 10, 35, 1) 100%
  );

  div[class='fp-overflow'] {
    // max-height: unset !important;

    @media (max-width: $tablet) {
      max-width: 100vh !important;
      overflow: hidden;
    }
  }

  div[class='container'] {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    overflow: hidden;

    position: relative;

    // height: fit-content !important;

    @media (max-width: $tablet) {
      height: 100% !important;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      pointer-events: none;

      @media (max-width: $tablet) {
        overflow: hidden;
      }

      h3 {
        height: fit-content;

        opacity: 0;
        translate: 0 -25%;
        transition-duration: 1s;
        transition-delay: 0.65s;
      }

      .tokenomics {
        margin: 0 auto;

        width: 65%;

        flex: 1;

        display: flex;
        justify-content: center;
        gap: 40px;

        .tokenomic {
          flex: 1;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          background-color: $black;
          border-radius: 50px;

          opacity: 0;
          translate: 0 -10%;
          transition-duration: 0.75s;

          .title {
            margin: 20px 0 40px;
          }

          .subtitle {
            font-size: 20px;
            color: rgba(255, 255, 255, 0.5);
          }

          .icon {
            width: 70px;
            height: 70px;

            object-fit: contain;
            object-position: center;
          }
        }
      }
    }

    > div {
      @media (max-width: $tablet) {
        height: 100%;
      }

      // &:nth-child(2) {
      //   flex: 0.6;
      // }

      // &:nth-child(3) {
      //   flex: 0.4;
      // }
    }
  }
}

@import "/src/app/styles/variables";

.hero {
  div[class="container"] {
    padding-inline: 0 !important;
    position: relative;

    min-height: 100vh;
    height: fit-content !important;

    @media (max-width: $laptop_big) {
      min-height: unset !important;
      height: fit-content !important;
    }

    @media (min-height: 1080px) {
      min-height: 100% !important;
    }
  }

  .wrapper {
    padding-top: 35px;

    width: 100%;
    height: 30%;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 25px;

    position: relative;
    z-index: 1;

    /* overflow: hidden; */

    @media (max-width: $laptop_big) {
      transform-origin: top center;
      gap: 30px;
    }

    @media (max-width: $tablet) {
      padding-inline: 16px;
    }

    h2 {
      opacity: 0;
      translate: 0 50%;

      transition-duration: 1s;
      transition-delay: 0.25s;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      font-size: 18px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;

        opacity: 0;
        translate: 0 50%;

        transition-duration: 1s;
        transition-delay: 0.5s;

        gap: 8px;

        @media (max-width: $tablet) {
          flex-direction: column;
        }

        img {
          width: 20px;
          height: 20px;

          object-fit: cover;
          object-position: center;
        }
      }

      span {
        color: $gray;
      }
    }

    .connectWallet {
      opacity: 0;
      translate: 0 50%;

      transition-duration: 1s;
      transition-delay: 0.65s;
    }

    .claimETH {
      opacity: 0;
      translate: 0 50%;

      transition-duration: 1s;
      transition-delay: 1.15s;
    }

    .cards {
      max-width: 50%;
      width: 100%;
      margin-top: 100px;
      display: flex;
      align-items: stretch;
      justify-content: center;

      gap: 20px;

      @media (max-width: $tablet) {
        flex-direction: column;
        gap: 16px;
        max-width: 100%;
      }

      .card {
        padding: 20px;

        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: rgba(99, 65, 174, 0.6);
        backdrop-filter: blur(18.5px);

        flex: 1;

        opacity: 0;
        translate: 0 50%;

        transition-duration: 1s;
        transition-delay: 0.75s;

        @media (max-width: $tablet) {
          padding: 30px;
          width: 100%;
        }

        &:nth-child(2) {
          transition-delay: 0.9s;
        }

        &:nth-child(3) {
          transition-delay: 1.05s;
        }

        .head {
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          text-transform: uppercase;

          padding-bottom: 4px;

          border-bottom: 1px solid #ffffff2e;

          @media (max-width: $tablet) {
            padding-bottom: 24px;
          }
        }

        .body {
          padding: 40px 0 30px;
          display: flex;
          justify-content: center;
          font-size: 32px;

          @media (max-width: $tablet) {
            padding: 36px 0 10px;
          }
        }
      }
    }

    .notice {
      max-width: 25%;
      width: 100%;
      margin: 0 auto;

      opacity: 0;
      translate: 0 50%;

      transition-duration: 1s;
      transition-delay: 1.45s;

      p {
        line-height: 120%;

        display: flex;
        align-items: center;
        justify-content: center;

        gap: 8px;
      }

      @media (max-width: $tablet) {
        max-width: 100%;
      }

      &Icon {
        width: 20px;
        height: 20px;

        min-width: 20px;
        min-height: 20px;

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .starsWrap {
    position: absolute;

    top: 0;
    left: 0;

    height: 75%;
    width: 100%;

    pointer-events: none;

    .stars {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      scale: 2;
      rotate: 180deg;
      z-index: 1;
      translate: 0 -25%;

      @media (max-width: $tablet) {
        translate: 0;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bottomShadow {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 50%;

    z-index: 4;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 20%
    );
  }

  .grid {
    position: absolute;

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    right: 0;

    z-index: 0;

    pointer-events: none;

    perspective: 1000px;

    display: flex;
    align-items: end;
    justify-content: center;

    @media (max-width: $tablet) {
      position: relative;

      top: unset;
      left: unset;
      right: unset;

      height: 230px;

      z-index: 1;
    }

    .polygon {
      transform-style: preserve-3d;
      transform: rotateX(70deg) translateY(0);

      position: relative;

      pointer-events: none;

      @media (max-height: 650px) {
        transform: rotateX(70deg) translateY(-125px);
      }

      &::before,
      &::after {
        content: "";
        position: absolute;

        height: 100%;
        width: 50%;

        z-index: 4;
      }

      &::before {
        left: -3px;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &::after {
        right: -3px;

        background: linear-gradient(
          260deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 50%
        );
      }

      .topGradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-image: url(/images/Roadmap/top-gradient.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 20%;
        transform: rotateX(70deg) translateZ(50px);
        z-index: 5;
      }

      svg {
        position: relative;
        z-index: 3;
      }

      .gradientBig {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: radial-gradient(
          200.13% 100.03% at 49.9% 69.74%,
          #651fff 1%,
          rgba(0, 0, 0, 0) 100%
        );

        z-index: 0;
      }
    }

    svg {
      width: 100%;
      height: 100%;

      scale: 1;

      @media (max-width: $tablet) {
        translate: 0 -2%;
      }
    }
  }

  footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    @media (max-width: $laptop_big) {
      position: relative !important;
    }

    @media (max-width: $tablet) {
      position: static;
    }
  }
}

// fonts
$helveticaNeueCryt: 'Helvetica Neue Cyr', sans-serif;

// colors
$bg: #000105;
$black: #0f0f0f;
$gray: #898989;

// breakpoints
$laptop_big: 1760px;
$laptop: 1440px;
$laptop_small: 1280px;
$tablet_big: 1140px;
$tablet: 768px;
$tablet_small: 656px;
$mobile_big: 565px;
$mobile: 465px;
$mobile_small: 376px;

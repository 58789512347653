*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-transform: capitalize;
}

button {
  display: block;
  border: none;
  font-family: inherit;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}
input:focus,
button:focus,
textarea:focus {
  outline: none;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  font-family: inherit;
  background: none;
  border: none;
  outline: none;
  max-width: 100%;
  resize: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
  }
}
img {
  display: block;
  max-width: 100%;
  -webkit-user-drag: none;
  user-select: none;
}
span {
  font: inherit;
  display: inline-block;
}

@import "/src/app/styles/variables";

.tokenomics {
  height: fit-content !important;
  background-color: $bg;

  div[class='fp-overflow'] {
    @media (max-width: $tablet) {
      height: fit-content !important;
      max-height: unset !important;
      overflow-y: hidden !important;
    }
  }

  div[class='container'] {
    @media (max-width: $tablet) {
      height: fit-content !important;
      overflow-y: hidden !important;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    pointer-events: none;

    @media (max-width: $tablet) {
      padding-bottom: 0px;
      overflow: hidden !important;
    }

    > div {
      &:nth-child(1) {
        @media (min-width: 2000px) and (min-height: 1500px) {
          scale: 2;
          transform-origin: bottom center;
        }
      }
    }

    .toRoadmap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      margin: 0 auto;

      font-size: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    h2 {
      height: fit-content;

      opacity: 0;
      translate: 0 -25%;
      transition-duration: 1s;
      transition-delay: 0.65s;
    }

    .tokenomics {
      margin: 0 auto;

      width: 100%;

      height: 325px;
      min-height: 325px;

      display: flex;
      justify-content: center;
      gap: 40px;

      @media (min-width: 2000px) and (min-height: 1500px) {
        height: 550px;
        min-height: 550px;
      }

      @media (max-width: $laptop_big) {
        min-height: 300px;
        height: 300px;
      }

      @media (max-width: $tablet_big) {
        gap: 25px;
      }

      @media (max-width: $laptop) {
        min-height: 250px;
        height: 250px;
      }

      @media (max-width: $tablet) {
        flex-direction: column;
        min-height: unset;
        height: unset;

        gap: 16px;
      }

      .tokenomic {
        flex: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        background-color: $black;
        border-radius: 50px;

        opacity: 0;
        translate: 0 -10%;
        transition-duration: 0.75s;

        @media (min-width: 2000px) and (min-height: 1500px) {
          justify-content: space-evenly;
        }

        > * {
          @media (min-width: 2000px) and (min-height: 1500px) {
            scale: 1.5;
          }
        }

        @media (max-width: $tablet) {
          min-height: 340px;
          height: fit-content;
        }

        .title {
          margin: 20px 0 40px;

          @media (min-width: 2000px) and (min-height: 1500px) {
            margin: 0;
          }

          @media (max-width: $laptop) {
            margin: 10px 0 20px;
          }

          @media (max-width: $tablet) {
            margin: 36px 0 24px;
          }
        }

        .subtitle {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.5);
        }

        .icon {
          width: 100px;
          height: 100px;

          object-fit: contain;
          object-position: center;

          @media (max-width: $laptop) {
            width: 70px;
            height: 70px;
          }

          @media (max-width: $tablet_big) {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

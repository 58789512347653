@import "/src/app/styles/variables";

.footer {
  width: 100%;

  position: relative;

  padding-top: 70px;

  overflow: unset !important;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 78%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (min-width: 2000px) and (min-height: 1500px) {
    padding-top: 140px;
  }

  @media (max-width: $tablet) {
    padding-top: 25px;
  }

  .content {
    padding: 25px 15px;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    @media (min-width: 2000px) and (min-height: 1500px) {
      padding: 105px 50px;
    }

    &::before {
      content: "";
      display: block;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      height: 1px;

      width: 100%;

      background: linear-gradient(
        90deg,
        rgba(0, 1, 5, 1) 0%,
        rgba(74, 74, 74, 1) 50%,
        rgba(0, 1, 5, 1) 100%
      );

      z-index: 1;

      @media (max-width: $tablet) {
        top: 0;
      }
    }
  }

  &Logo {
    width: 160px;
    height: 55px;

    opacity: 0;
    translate: 0 25%;

    transition-duration: 1s !important;
    transition-delay: 0.7s !important;

    z-index: 1;

    > * {
      @media (min-width: 2000px) and (min-height: 1500px) {
        transform-origin: left center;
        scale: 2;
      }
    }

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &Cols {
    display: flex;
    gap: 50px;

    z-index: 1;

    @media (min-width: 2000px) and (min-height: 1500px) {
      scale: 2;
      transform-origin: right center;
    }

    @media (max-width: $tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 55px 20px;

      width: 100%;
    }

    .col {
      display: flex;
      flex-direction: column;

      gap: 20px;

      line-height: 131.25%;

      opacity: 0;
      translate: 0 15%;

      transition-duration: 1s;
      transition-delay: 0.7s;

      @media (max-width: $tablet) {
        flex: 1;
      }

      .head {
        opacity: 0.4;

        @media (max-width: $tablet) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        gap: 6px;

        opacity: 0.8;

        @media (max-width: $tablet) {
          gap: 16px;
          align-items: center;
        }
      }
    }
  }
}

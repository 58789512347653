.contract-header {
  color: #eee;
  font-size: 18px;
  white-space: nowrap;
}

.hero-button-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.copy-clipboard-img {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.copy-clipboard-img-test {
  height: 100%;
  width: 100%;
}

.copy-clipboard-img-test:active {
  scale: 1.2;
}

.logo {
  width: inherit;
  height: inherit;

  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;

    path {
      display: block;

      transition-duration: 0.75s;

      &:nth-child(1) {
        transition-delay: 0.05s;
      }

      &:nth-child(2) {
        transition-delay: 0s;
      }

      &:nth-child(3) {
        transition-delay: 0.075s;
      }

      &:nth-child(4) {
        transition-delay: 0.1s;
      }

      &:nth-child(5) {
        transition-delay: 0.125s;
      }

      // &:nth-child(6) {
      //   transition-delay: 0.5s;
      // }
    }
  }
}

.flashingStars {
  width: 100%;
  height: 100%;

  opacity: 0;
  transition-duration: 1s;
  transition-delay: 0.6s;

  svg {
    width: 100%;
    height: 100%;

    g {
      &.blink {
        animation: blink 4s backwards ease;
      }
    }
  }
}

@keyframes blink {
  50% {
    // opacity: 1;
    // scale: 2;
    // fill: #ffffffa7;
  }

  100% {
    // opacity: 0;
    //   fill: url(#paint11_radial_162_1715);
    // fill: red;
  }
}

@import "/src/app/styles/variables";

.roadmap {
  overflow: hidden !important;
  // height: fit-content !important;

  @media (max-width: $laptop_big) {
    height: fit-content !important;
  }

  .starsWrap {
    position: absolute;

    top: 0;
    left: 0;

    height: 75%;
    width: 100%;

    pointer-events: none;

    .stars {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      scale: 2;
      rotate: 180deg;
      z-index: 1;
      translate: 0 -25%;

      @media (max-width: $tablet) {
        translate: 0;
        opacity: 0.3;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bottomShadow {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 50%;

    z-index: 4;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 20%
    );
  }

  .grid {
    position: absolute;

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    right: 0;

    z-index: 0;

    pointer-events: none;

    perspective: 1000px;

    display: flex;
    align-items: end;
    justify-content: center;

    @media (min-width: 2000px) and (min-height: 1500px) {
      height: 95%;
      overflow: hidden;
    }

    @media (max-width: $tablet) {
      position: relative;

      top: unset;
      left: unset;
      right: unset;

      height: 230px;

      z-index: 1;
    }

    .polygon {
      transform-style: preserve-3d;
      transform: rotateX(70deg) translateY(0);

      position: relative;

      pointer-events: none;

      @media (min-width: 2000px) and (min-height: 1500px) {
        scale: 2;
      }

      @media (max-height: 650px) {
        transform: rotateX(70deg) translateY(-125px);
      }

      &::before,
      &::after {
        content: '';
        position: absolute;

        height: 100%;
        width: 50%;

        z-index: 4;
      }

      &::before {
        left: -3px;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &::after {
        right: -3px;

        background: linear-gradient(
          260deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 50%
        );
      }

      .topGradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-image: url(/images/Roadmap/top-gradient.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 20%;
        transform: rotateX(70deg) translateZ(50px);
        z-index: 5;
      }

      svg {
        position: relative;
        z-index: 3;
      }

      .gradientBig {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: radial-gradient(
          200.13% 100.03% at 49.9% 69.74%,
          #651fff 1%,
          rgba(0, 0, 0, 0) 100%
        );

        z-index: 0;
      }
    }

    svg {
      width: 100%;
      height: 100%;

      scale: 1;

      @media (max-width: $tablet) {
        translate: 0 -2%;
      }
    }
  }

  div[class='fp-overflow'] {
    @media (max-width: $tablet) {
      height: fit-content !important;
      overflow-y: hidden !important;
      max-height: fit-content !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  div[class='container'] {
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    // height: fit-content !important;
    overflow: hidden;

    padding-inline: 0px !important;

    @media (max-width: $tablet) {
      overflow: unset !important;
      padding-bottom: 100px;
      height: fit-content !important;
    }
  }

  footer {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;

    z-index: 3;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    gap: 100px;

    height: 100%;

    z-index: 2;

    @media (min-width: 2000px) and (min-height: 1500px) {
      justify-content: center;
      scale: 1.75;
    }

    @media (max-width: $laptop) {
      scale: 0.8;
    }

    @media (max-width: $tablet) {
      padding: 0 15px;
      scale: 1;
    }

    h2 {
      opacity: 0;
      translate: 0 25%;
      transition-duration: 1s !important;
      transition-delay: 0.5s !important;
    }

    .cards {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      gap: 44px;

      z-index: 1;

      div[class*='swiper'] {
        height: 100%;

        > div[class*='swiper-wrapper'] {
          height: 100%;

          > div {
            min-width: unset;
            width: 85% !important;
            max-width: unset;
            height: 100%;

            &:nth-child(1) {
              .block {
                @media (max-width: $tablet) {
                  background: rgba(0, 0, 0, 1);
                }
              }
            }

            &:nth-child(3) {
              @media (max-width: $tablet) {
                backdrop-filter: blur(18.5px);
                background: rgba(0, 0, 0, 0.41);

                .layer {
                  display: block !important;
                }
              }
            }

            &:nth-child(2) {
              @media (max-width: $tablet) {
                .block {
                  @media (max-width: $tablet) {
                    border: 1px solid rgba(255, 255, 255, 0.4);
                    background: rgba(99, 65, 174, 0.6);
                    backdrop-filter: blur(18.5px);
                    // background: rgba(0, 0, 0, 1);
                  }
                }
              }
            }
          }
        }
      }

      &.desktopCards {
        .cardWrap {
          &:nth-child(2) {
            > div {
              border: 1px solid rgba(255, 255, 255, 0.4);
              background: rgba(99, 65, 174, 0.6);
              backdrop-filter: blur(18.5px);
            }
          }

          &:nth-child(3) {
            > div {
              backdrop-filter: blur(18.5px);
              background: rgba(0, 0, 0, 0.41);

              .layer {
                display: block !important;
              }
            }
          }

          &:nth-child(2) {
            transform: translate(0, -15%) !important;
          }

          &:nth-child(3) {
            transform: translate(0, -30%) !important;
          }
        }
      }

      @media (max-width: $tablet) {
        height: 500px;
        overflow-x: scroll;
      }

      .cardWrap {
        min-width: 360px;
        width: fit-content;

        min-height: 420px;
        height: 420px;

        perspective: 1000px;

        @media (max-width: $tablet_big) {
          min-height: 450px;
          height: 450px;
        }

        @media (max-width: $tablet) {
          min-height: 100%;
          height: 100%;
          width: 100% !important;
          min-width: unset !important;
        }

        &:nth-child(1) {
          > .block {
            transform: translateZ(-2000px) rotateX(-12.68deg) rotateY(90deg);
            transition-duration: 2s !important;

            @media (max-width: $tablet) {
              transform: translateZ(-2200px) rotateX(-60deg);
              transition-duration: 2s !important;
            }
          }
        }

        &:nth-child(2) {
          .block {
            @media (max-width: $tablet) {
              transform: translateZ(-2000px) rotateX(-60deg);
              transition-duration: 2s !important;
            }
          }
        }

        &:nth-child(3) > .block {
          transform: translateZ(-2000px) rotateX(12.68deg) rotateY(-90deg)
            rotateZ(45deg);
          transition-duration: 2s !important;

          transition-delay: 1s !important;

          @media (max-width: $tablet) {
            transform: translateZ(-2400px) rotateX(-60deg);
            transition-duration: 2s !important;
          }
        }
      }

      @media (max-width: $tablet) {
        height: 500px;
        position: relative;
      }
    }

    .block {
      border-radius: 22px;
      position: relative;

      width: 100%;
      height: 100%;

      padding: 40px 30px;

      border-radius: 22px;
      overflow: hidden;

      opacity: 0;
      scale: 0.8;

      background: rgba(0, 0, 0, 0.41);
      backdrop-filter: blur(18.5px);
      border: 1px solid rgba(255, 255, 255, 0.4);

      @media (max-width: $tablet) {
        height: 100%;
        width: 100% !important;
      }

      &:hover {
        .layerTwo {
          background-position: 100% 100% !important;
        }

        .layerOne {
          background-position: 100% 100% !important;
        }
      }

      transition-duration: 1.5s;
      transition-delay: 0.5s;
      transform-style: preserve-3d;

      // &.filled {
      //   backdrop-filter: blur(18.5px);
      //   background: rgba(0, 0, 0, 0.41);
      // }

      .head {
        width: 100%;

        border-bottom: 1px solid rgba(255, 255, 255, 0.33);
        padding-bottom: 20px;

        position: relative;
        z-index: 4;
      }

      .body {
        width: 100%;
        padding-top: 25px;

        z-index: 4;
        position: relative;

        ul {
          width: 100%;

          display: flex;
          flex-direction: column;
          gap: 8px;

          li {
            display: flex;
            align-items: center;
            gap: 16px;

            line-height: 131.25%;

            .icon {
              width: 18px;
              height: 18px;

              img {
                width: 100%;
                height: 100%;

                object-fit: contain;
                object-position: center;
              }
            }
          }
        }
      }

      .layer {
        display: none;

        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        transition: background-position 0.5s ease;

        &.layerOne {
          background-image: linear-gradient(
            229deg,
            #fff 0%,
            #ff82e0 38.49%,
            #996bff 100%
          );

          z-index: 3;
          mix-blend-mode: overlay;
        }

        &.layerTwo {
          background-image: linear-gradient(
            225deg,
            #fff 0%,
            #ff82e0 48.21%,
            #996bff 100%
          );
          mix-blend-mode: color-burn;
          z-index: 2;

          background-size: 300%;

          background-repeat: repeat-x;
        }

        &.layerThree {
          background-image: rgba(194, 157, 255, 0.42);

          z-index: 3;
        }
      }
    }
  }
}

@import "/src/app/styles/variables";

.features-slider-wrapper {
  height: 100vh;
  position: relative;

  @media (max-width: $tablet) {
    overflow: hidden;
  }

  .stars {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .bg-scene {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    @media (max-width: $tablet) {
      scale: 1.2;
    }

    &::before {
      content: "";
      display: block;

      width: 100%;
      position: absolute;

      bottom: 0;
      left: 0;
      right: 0;

      height: 20%;
      background: linear-gradient(
        0deg,
        rgb(0, 0, 0) 60%,
        rgba(255, 255, 255, 0) 100%
      );

      mix-blend-mode: color-burn;

      z-index: 2;

      @media (max-width: $tablet) {
        height: 40%;
      }
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;

      z-index: 1;
    }
  }

  .light-scene {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 50%;

    translate: -50% 0;

    z-index: 3;

    @media (max-width: $tablet) {
      translate: -50% 25%;
      z-index: 1;
      height: 100%;
    }

    .scroll-label {
      position: absolute;

      left: 50%;

      display: flex;
      align-items: center;
      gap: 24px;

      margin-top: auto;

      z-index: 1;

      translate: -50% 250%;

      bottom: 0;

      span {
        text-transform: capitalize;
        letter-spacing: -0.8px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;

      opacity: 0;

      transition: opacity 1s ease 1s !important;
    }
  }

  .slide-info {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 15px;
    left: 50%;

    translate: -50% 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    gap: 35px;

    z-index: 3;

    height: fit-content;

    margin: 15px auto 0;

    @media (min-width: 2000px) and (min-height: 1500px) {
      scale: 2 !important;
      transform-origin: top center;
    }

    @media (max-width: $laptop_big) {
      scale: 0.8;
    }

    @media (max-width: $laptop) {
      scale: 0.75;
      transform-origin: top center;
    }

    @media (max-width: $laptop_big) {
      gap: 15px;
    }

    @media (max-width: $tablet) {
      scale: 0.95;
    }

    .slide-arrows {
      max-width: 45%;
      width: 100%;

      margin: 0 auto;

      display: flex;
      align-items: center;
      justify-content: space-between;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      translate: 0 -25%;

      @media (max-width: $tablet_big) {
        max-width: 75%;
      }

      .arrow {
        width: 20px;
        height: 25px;

        cursor: pointer;

        svg {
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        path {
          fill: #898989;

          animation: flash 2s infinite linear;
        }
      }

      @media (max-width: $tablet) {
        display: none;
      }
    }

    .slide-labels {
      max-width: 40%;
      width: 100%;

      margin: 0 auto;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: $tablet_big) {
        max-width: 65%;
      }

      @media (max-width: $tablet) {
        display: none;
      }

      span {
        color: #fff;
        font-size: 14px;
        line-height: 170%;

        transition: color 0.4s ease;

        &.active {
          color: #898989;
        }
      }
    }

    .section-span {
      > div {
        opacity: 0;
        translate: 0 25%;
        transition-duration: 0.75s;
      }
    }

    .list {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      word-spacing: 2px;
      flex-wrap: wrap;

      max-width: 50%;
      width: 100%;

      font-size: 20px;
      color: rgba(255, 255, 255, 0.8);

      @media (max-width: $laptop_big) {
        max-width: 75%;
      }

      @media (max-width: $laptop_small) {
        max-width: 85%;
      }

      @media (max-width: $tablet_big) {
        max-width: 100%;
      }

      @media (max-width: $tablet) {
        font-size: 16px;

        /*  display: grid;
        grid-template-columns: repeat(2, 1fr); */

        max-width: 100%;
      }

      li {
        display: flex;
        align-items: center;
        gap: 10px;

        opacity: 0;
        translate: 0 15%;
        transition-duration: 0.75s;

        &::before {
          content: "";

          display: block;

          width: 4px;
          height: 4px;

          border-radius: 50%;
          background: linear-gradient(
            243deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(141, 66, 231, 1) 100%
          );
        }
      }
    }

    h2 {
      position: relative;

      opacity: 0;
      translate: 0 25%;
      transition-duration: 1s;
      transition-delay: 0.25s;

      @media (max-width: $tablet) {
        text-align: center;
      }

      img {
        width: 54px;
        height: 54px;

        position: absolute;
        top: 0;
        right: 0;

        object-fit: cover;
        object-position: center;

        translate: 50% -25%;

        z-index: 1;

        @media (max-width: $tablet) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .bg-scene {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    @media (max-width: $tablet) {
      translate: 0 25%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.features-slider {
  width: 100% !important;
  height: 100% !important;

  overflow: hidden !important;

  margin: 0 auto;

  z-index: 3 !important;

  .swiper-wrapper {
    align-items: end;

    transition-timing-function: ubic-bezier(0.85, 0, 0.15, 1) !important;
  }

  .features-slider__slide {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60%;

    opacity: 0;

    translate: 0 -25%;

    transition: opacity 0.2s ease;

    @media (max-width: $tablet) {
      translate: 0;
    }

    &.swiper-slide-active {
      opacity: 1;
      transition-duration: 1s;
    }

    &:nth-child(4) {
      .img {
        max-width: 400px;

        @media (min-width: 2000px) and (min-height: 1500px) {
          scale: 1.25 !important;
          max-width: 40%;
        }

        @media (max-width: $laptop) {
          max-width: 250px;
        }
      }
    }

    .img {
      max-width: 250px;
      width: 100%;
      opacity: 0;
      transform: translateY(-10%); /* Corrected translation property */

      transition-duration: 1s !important;
      transition-delay: 0.75s !important;

      @media (min-width: 2000px) and (min-height: 1500px) {
        transform: scale(1.25) !important; /* Corrected scale property */
        max-width: 25%;
      }

      @media (max-width: $laptop) {
        max-width: 150px;
      }

      @media (max-width: $tablet) {
        max-width: 100%;
        min-width: 100%;
      }

      .wrapper {
        width: 100%;

        padding-top: 100%;
        position: relative;
        animation-name: levitation;
        animation-duration: 4.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        @media (max-width: $tablet) {
          padding-top: 50%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  .swiper-scrollbar {
    position: absolute !important;

    top: 0 !important;
    left: 50% !important;

    translate: -50% 100% !important;

    border-radius: 100%;

    scale: 0.36 !important;

    background: linear-gradient(
      61deg,
      rgba(0, 1, 5, 1) 0%,
      rgba(74, 74, 74, 1) 50%,
      rgba(0, 1, 5, 1) 100%
    );

    @media (max-width: $tablet_big) {
      scale: 0.5 !important;
    }

    @media (max-width: $tablet) {
      scale: 1 !important;
      translate: -50% 250% !important;
    }

    .swiper-scrollbar-drag {
      border-radius: 100%;
      background: linear-gradient(
        61deg,
        rgba(74, 74, 74, 1) 0%,
        rgba(88, 105, 190, 1) 25%,
        rgba(119, 142, 255, 1) 75%,
        rgba(74, 74, 74, 1) 100%
      );
    }
  }
}

@keyframes levitation {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10%);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes flash {
  50% {
    fill: #fff;
  }

  100% {
    fill: $gray;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-specific {
      margin-top: 750px;
    }
  }
}

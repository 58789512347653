@import "/src/app/styles/variables";

.comingSoon {
  background: linear-gradient(180deg, #000 0%, rgb(5, 10, 36) 100%);

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;

    text-align: center;

    padding-top: 100px;

    z-index: 2;
    position: relative;

    @media (min-height: 950px) and (max-width: 1760px) {
      scale: 1.25;
      width: 100%;
      padding-bottom: 12.5%;
      transform-origin: top center;
    }

    @media (min-width: 2000px) and (min-height: 1500px) {
      scale: 2;
      width: 100%;
      padding-top: 0;
      padding-bottom: 12.5%;
    }

    @media (max-width: $laptop) {
      gap: 20px;
      padding-top: 35px;
    }

    @media (max-width: $tablet_big) {
      padding-top: 0;
    }

    @media (max-width: $tablet) {
      gap: 45px;
      padding-top: 140px;
    }

    .tickerWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      position: relative;

      max-width: 30%;
      width: 100%;

      opacity: 0;
      translate: 0 50%;

      transition-delay: 1.15s;
      transition-duration: 1s;

      @media (min-width: 1920px) {
        max-width: 25%;
      }

      @media (max-width: $tablet) {
        max-width: 100%;
      }

      .circle {
        width: 6px;
        min-width: 6px;
        height: 6px;
        min-height: 6px;
        border-radius: 50%;
        background: #d9d9d9;

        position: absolute;
        top: 50%;

        &:nth-child(1) {
          left: -10px;
        }

        &:nth-child(3) {
          right: -10px;
        }

        translate: 0 -50%;
      }
    }

    .ticker {
      border-radius: 10px;
      height: 42px;

      background: rgba(99, 65, 174, 0.6);

      border: 1px solid rgba(255, 255, 255, 0.4);

      padding: 3px 0;

      line-height: 100%;

      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;

      position: relative;

      .string {
        display: block;
        white-space: nowrap;

        opacity: 1 !important;

        color: #fff;
        text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.75);
        font-size: 14px;
        font-weight: 400;
        line-height: 170%;
        text-transform: uppercase;

        flex: 0 0 auto;
        animation: 15s linear 0s infinite ticker;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        gap: 6.5px;
        .line {
          width: 100%;
          height: 0.5px;
          min-height: 0.5px;
          background-color: rgba(255, 255, 255, 0.16);
        }
      }
    }

    .title {
      max-width: 50%;
      width: 100%;

      @media (max-width: $tablet_big) {
        max-width: 100%;
      }

      h1 {
        text-transform: uppercase !important;
        opacity: 0;
        translate: 0 25%;

        transition-duration: 1s;
        transition-delay: 1s;

        &:nth-child(2) {
          transition-delay: 1.25s;
        }

        &:nth-child(3) {
          transition-delay: 1.5s;
        }
      }
    }

    p {
      color: $gray;
      font-size: 18px;
      line-height: 140%;

      max-width: 385px;
      width: 100%;

      opacity: 0;
      translate: 0 25%;

      transition-duration: 1s;
      transition-delay: 1.5s;

      @media (max-width: $tablet) {
        font-size: 16px;
        line-height: 130%;
      }

      span {
        opacity: 0;
        translate: 0 25%;

        transition-duration: 1s;
        transition-delay: 1.65s;
      }
    }

    > div:nth-child(2) {
      opacity: 0;
      translate: 0 25%;

      transition-duration: 1s;
      transition-delay: 1s;
    }
  }

  h1 {
    overflow: hidden;

    span {
      opacity: 0;
      translate: 0 100%;
      rotate: 25deg;
    }
  }

  .grid {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    z-index: 0;

    translate: -50% -50%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;

      z-index: 1;
    }

    &::after {
      top: 0;

      height: 50%;

      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(0, 0, 0) 62%
      );

      @media (min-width: 2000px) and (min-height: 1500px) {
        height: 25%;
      }
    }

    &::before {
      bottom: 0;

      height: 25%;

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(5, 10, 36, 1) 82%
      );
    }

    > div {
      width: 100%;
      height: 100%;

      opacity: 0;

      transition-duration: 0.5s;
    }

    svg {
      scale: 1.15;
      width: 100%;
      height: 100%;
      // translate: 2.25% 0;

      @media (min-width: 2000px) and (min-height: 1500px) {
        scale: 1.5 !important;
      }

      @media (min-height: 1080px) and (min-width: 1920px) {
        scale: 1;
      }

      @media (min-width: 2000px) and (min-height: 1500px) {
        translate: 2.5% 0;
      }

      @media (max-width: $laptop_small) {
        scale: 1;
      }
    }
  }

  div[class='container'] {
    @media (min-width: 2000px) and (min-height: 1500px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .stars {
    max-width: 1920px;

    width: 100%;
    // width: 100vw;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 50%;

    translate: -50% -25%;

    @media (min-width: 2000px) and (min-height: 1500px) {
      translate: -50% -25%;
      scale: 1;
    }

    @media (max-width: $tablet) {
      translate: -50% 0;
    }

    svg {
      animation-name: levitationSmall;
      animation-duration: 4.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

      scale: 1.25;
    }
  }

  .scene {
    position: absolute;

    bottom: 0;
    left: 50%;

    translate: -50% -10%;

    pointer-events: none;

    z-index: 1;

    transform-origin: center bottom;

    @media (min-height: 950px) and (max-width: 1760px) {
      scale: 1 !important;
    }

    @media (min-width: 2000px) and (min-height: 1500px) {
      translate: -50% -50%;
      scale: 2;
      transform-origin: bottom center;
    }

    @media (max-width: $laptop_big) {
      translate: -50% 0;
      scale: 0.7;
    }

    @media (max-width: $laptop_small) {
      scale: 0.5;
    }

    @media (max-width: $tablet) {
      scale: 1;
      translate: -50% 0;
    }

    .telegram {
      width: 215px;
      height: 195px;

      position: absolute;

      top: 0;
      left: 50%;

      translate: -50% -50%;

      z-index: 3;

      @media (max-width: $tablet) {
        width: 135px;
        height: 110px;
      }

      > div {
        width: 100%;
        height: 100%;
        animation-name: levitation;
        animation-duration: 4.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;

        opacity: 0;
        translate: 0 -25%;

        transition-delay: 0.7s;
        transition-duration: 1.5s;
      }
    }

    .light {
      position: absolute;

      width: 1500px;
      height: 1050px;

      top: 50%;
      left: 50%;

      translate: -50% -82%;

      z-index: 2;

      @media (max-width: $tablet) {
        width: 556px;
        height: 425px;
      }

      img {
        width: 100%;
        height: 100%;

        opacity: 0;

        object-fit: contain;
        object-position: center;

        transition: opacity 1s ease 0.5s;
      }
    }

    .platform {
      width: 380px;
      height: 300px;

      z-index: 1;
      position: relative;

      opacity: 0;
      translate: 0 -10%;
      transition-duration: 1s;

      @media (max-width: $tablet) {
        width: 250px;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
      }
    }
  }
}

@keyframes levitation {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15%);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes levitationSmall {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 0.5%);
  }
  100% {
    transform: translate(0, 0px);
  }
}
